<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/' + database" title="Contactos" collection="eloqua_contacts" templateTable="TableContactForm" templateTableModal="TableContactModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        ChannelType: 'Canal',
        TipoDeLead_c: 'Tipo de lead',
        PrimaryContactPersonLastName: 'Nombre',
        PrimaryContactPersonLastName: 'Apellido paterno',
        RUToRUTProvisionalDeContacto_c: 'Número de documento',
        DNIoPasaporte_c: 'DNI o Pasaporte',
        TipoDeCorreo_c: 'Tipo de correo',
        PrimaryContactEmailAddress: 'E-mail',
        UnidadDeNegocio_c: 'Unidad de negocio',
        segemntacion_c: 'Segmentación',
        PaisDeOrigen_c: 'Nacionalidad',
        TipoDeTelefono_c: 'Tipo de teléfono',
        PrimaryPhoneCountryCode: 'Código celular',
        PrimaryPhoneAreaCode: 'Código teléfono',
        PrimaryPhoneNumber: 'Número celular',
        utm_source: 'UTM Source',
        utm_medium: 'UTM Medium',
        utm_campaign: 'UTM Campaign',
        device: 'Dispositivo',
        historial: 'Historial',
        mensaje_c: 'Mensaje',
      },
      query: {
        collection: 'eloqua_contacts',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
